<template>
  <div class="tabs">
    <v-tabs dark background-color="darkGrey" slider-color="primary" height="43">
      <v-tab
        v-for="item in navigation"
        :key="item.title"
        :title="item.title"
        @change="getNavigationPathAndSetCurrentPage(item.link)"
        @click="getNavigationPathAndSetCurrentPage(item.link)"
      >
        {{ getTranslation(item.title) }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'sevenTabs',
  methods: {
    ...mapActions([
      'setCurrentPage',
      'setInfo',
      'setSelectedFiltersPerTenantValues',
      'clearInitialQueryParams',
      'setSelectedFiltersPerTenant',
    ]),
    getNavigationPathAndSetCurrentPage(value) {
      this.setInfo(null);
      if (this.$route.path !== value) {
        this.clearInitialQueryParams();
        this.setSelectedFiltersPerTenant({ search: '' });
        this.$router.push(value);
      }
      this.setCurrentPage(value.replace('/', ''));
    },
  },
  computed: {
    ...mapGetters(['navigation', 'getTranslation']),
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  ::v-deep .v-tabs-slider-wrapper {
    top: 0;
  }

  ::v-deep .v-tabs-slider {
    height: 3px;
  }
}
</style>
